<template>
  <div>
    <div class="box_top">
      <templateHeader title="经济社会发展水平主要情况" />
      <div class="export_box">
        <el-button type="primary" size="mini" plain icon="el-icon-download" @click="exportExcel">导出</el-button>
        <el-button type="primary" size="mini" icon="el-icon-upload2" @click="importExcel">导入</el-button>
      </div>
    </div>

    <el-row>
      <el-col :span="3">
        <templateTree ref="templateTree" :treeData="treeData" :dck="dck" @refreshNode="refreshNode" />
      </el-col>
      <el-col :span="21">
        <div class="table_right">
          <el-table
            v-loading="loading"
            ref="table"
            :data="listData"
            style="width: 100%;position: absolute;"
            height="660px"
            :span-method="objectSpanMethod"
          >
            <el-table-column type="index" label="序号" width="50" align="center">
              <template slot-scope="scope">
                <span v-if="scope.$index != 0">{{
                  (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index
                }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="county" width="100" label="县（市区）" align="center"></el-table-column>
            <el-table-column prop="totalPopulation" width="140" label="常住人口（万人）" align="center">
              <template slot-scope="scope">
                <span v-if="scope.$index != 0"> {{ formatTwoDecimalPlaces(scope.row.totalPopulation) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="townships" width="140" label="乡镇、街道（个）" align="center"></el-table-column>
            <el-table-column prop="communitys" width="150" label="行政村、社区（个）" align="center"></el-table-column>
            <el-table-column prop="groups" width="80" label="组（个）" align="center"></el-table-column>

            <el-table-column width="460" label="地区生产总值（亿）" align="center">
              <el-table-column prop="gdp" label="总值" align="center"> </el-table-column>
              <el-table-column prop="primaryIndustry" label="第一产业" align="center"> </el-table-column>
              <el-table-column prop="perCapitaGDP" width="150" label="人均GDP（万人）" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.$index != 0"> {{ formatTwoDecimalPlaces(scope.row.perCapitaGDP) }}</span>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column width="460" label="农村居民基本情况" align="center">
              <el-table-column prop="primaryIndustryPopulation" width="150" label="常住人口（万人）" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.$index != 0">
                    {{ formatTwoDecimalPlaces(scope.row.primaryIndustryPopulation) }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="perCapitaIncome" width="180" label="人均可支配收入（元）" align="center">
                <template slot-scope="scope">
                  {{ formatNumericWithTwoDecimalPlaces(scope.row.perCapitaIncome) }}
                </template>
              </el-table-column>
              <el-table-column prop="perCapitaArableLand" width="150" label="人均耕地（亩）" align="center">
              </el-table-column>
              <el-table-column prop="perCapitaHousingArea" width="150" label="人均住房面积（㎡）" align="center">
              </el-table-column>
            </el-table-column>
          </el-table>

          <Pagination :isHomePage1="true" v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
      </el-col>
    </el-row>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
  </div>
</template>

<script>
import { _getLevelRegion } from '@/api/keepHome'
import {
  _EconomicPageEconomic,
  _EconomicExport
} from '@/api/modular/postImmigrationService/HomePage/socialDevelopment.js'

import templateHeader from '@/components/templateView/templateHeader.vue'
import Pagination from '@/components/Pagination'
import tempalteImport from '@/components/templateView/tempalteImport.vue'

import templateTree from '@/components/templateView/templateTree.vue'

export default {
  name: 'HcYmhfIndex',
  data() {
    return {
      // 分页参数
      loading: false,
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      listData: [],
      treeData: [],
      dck: [],
      defaultProps: {
        children: 'childRegions',
        label: 'name'
      },
      OrgId: null,
      year: null,
      OrgPids: null,
      years: [],
      downloadObj: {},
      IsLevel: false
    }
  },
  components: {
    templateHeader,
    Pagination,
    tempalteImport,
    templateTree
  },

  methods: {
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },
    refreshTable() {
      this.init()
    },
    init() {
      this.getPage()
      this.getLevelRegion()
    },
    setYears(value, years) {
      this.year = value
      this.years = years
      this.init()
    },
    refreshNode(obj, IsLevel) {
      this.OrgId = obj.id
      this.downloadObj = obj
      this.IsLevel = IsLevel
      this.OrgPids = obj.pids
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
      this.getPage()
    },
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // if (columnIndex === 1) {
      //   if (row.deviceNum != 0) {
      //     return [row.deviceNum, 1]
      //   } else {
      //     // return [0, 0];
      //     if (row?.oneNum == 0) {
      //     } else {
      //       return [0, 0]
      //     }
      //   }
      // }
    },

    receiveId(value) {
      let obj = this.treeData.find(ele => ele.id == value)
      // this.treeData = [...this.treeData]
      if (value) {
        this.dck = JSON.parse(JSON.stringify([value]))
      } else {
        this.dck = []
      }
      this.downloadObj = obj
      this.OrgId = value ? value : null
      this.OrgPids = obj?.pids

      this.getPage()
    },

    getPage() {
      const { currentPage, pageSize, total } = this.pageparm

      let params = {
        year: this.year,
        OrgId: this.downloadObj ? this.downloadObj?.id : null,
        pageNo: currentPage,
        pageSize: pageSize,
        OrgPids: this.OrgPids,
        IsLevel: this.IsLevel
      }


      _EconomicPageEconomic(params).then(res => {
        if (res.code == 200) {
          this.listData = res.data.rows
          this.pageparm.total = res.data.totalRows

          if (res.data.totalRow) {
            this.listData.unshift(res.data.totalRow)
          }
        }
      })
    },
    exportExcel() {
      let params = {
        year: this.year,
        OrgId: this.downloadObj.id, //行政区域编号
        OrgName: this.downloadObj.name, //行政区域名称
        OrgPids: this.downloadObj.pids //行政区域编号path
      }
      _EconomicExport(params).then(res => {
        console.log(res)
        if (res.status == 200) {
          this.downloadfile(res)
        } else {
          this.$message.error('导出失败')
        }
      })
    },
    importExcel() {
      this.$refs.tempalteImport.view(this.year, this.years, '1-1-2')
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';
.box_top {
  display: flex;
  justify-content: space-between;
}

.table_left {
  width: 96%;
  border: 1px solid #eeeeee;
  & > .title {
    width: 100%;
    background: #f9fafe;
    padding: 10px 8px;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0em;
    color: #212a33;
  }
}

.table_right {
  width: 100%;
  position: relative;
  height: 700px;
}
</style>
